footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    90deg,
    var(--color-primary),
    var(--color-accent),
    var(--color-secondary)
  );
  color: var(--color-white);
  text-align: center;
  padding: 10px;
  font-size: 14px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

footer a {
  color: #ffffff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
