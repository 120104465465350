/* Tamanho base */
.btn {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Variantes de estilo */
.btn-primary {
  background-color: #6c63ff;
  color: white;
}

.btn-primary:hover {
  background-color: #5747d1;
}

.btn-secondary {
  background-color: #4caf50;
  color: white;
}

.btn-secondary:hover {
  background-color: #3d8c40;
}

.btn-danger {
  background-color: #f44336;
  color: white;
}

.btn-danger:hover {
  background-color: #d32f2f;
}

/* Tamanhos */
.btn-small {
  padding: 0.4rem 0.8rem;
  font-size: 0.875rem;
}

.btn-medium {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
}

.btn-large {
  padding: 1.2rem 2rem;
  font-size: 1.25rem;
}

/* Ícones */
.btn-icon {
  margin-right: 0.5rem;
  display: inline-block;
}
