.historico-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.historico-item {
  margin-bottom: 15px;
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  background-color: var(--color-white);
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-button {
  background: var(--color-primary);
  color: var(--color-white);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.details {
  margin-top: 10px;
  padding: 10px;
  background-color: var(--color-light-gray);
  border-radius: 5px;
}
