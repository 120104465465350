/* Estilo geral do container do Dashboard */
.dashboard-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Título principal */
.dashboard-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Subtítulo */
.dashboard-subtitle {
  font-size: 1.25rem;
  color: #555;
  margin-bottom: 30px;
}

/* Container dos cards */
.dashboard {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

/* Estilo do Card (estilize os cards diretamente ou no componente Card) */
.card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Ícone no Card */
.card-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #4caf50;
}

/* Título do Card */
.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Conteúdo do Card */
.card-content {
  font-size: 1rem;
  color: #555;
}
