/* mvp\front_web\linka\src\components\Header\Header.css */
.header {
  background: linear-gradient(
    90deg,
    var(--color-primary),
    var(--color-accent),
    var(--color-secondary)
  );
  padding: 1rem 2rem;
  color: var(--color-white);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: 1200px; */
  margin: 0 auto;
}

.logo-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  /* margin-right: auto; */
}

.nav-link {
  color: var(--color-white);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 1rem;
  transition: color 0.3s ease;
}

.nav-link:last-child {
  margin-right: 0;
}

.nav-link:hover {
  color: #f9f9f9;
}

/* Hamburger Menu */
.hamburger {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}
.hamburger:hover {
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Aumenta a opacidade no hover para melhor feedback visual */
}
/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.overlay.show {
  opacity: 1;
  pointer-events: all;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background: var(--color-white);
  margin: 1px 0;
  transition: all 0.3s ease;
}

.hamburger.open .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.hamburger:hover .line {
  background: var(--color-secondary);
}

/* Adicionando estilos para o ícone de notificação */
.user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--color-white);
  font-size: 1rem;
  font-weight: 500;
}

.notification-icon-container {
  position: relative;
}

.notification-icon {
  text-decoration: none;
  color: var(--color-white);
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.notification-icon:hover {
  transform: scale(1.1);
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  /* background-color: var(--color-secondary); */
  background-color: RED;
  color: var(--color-white);
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dropdown de Notificações */
.notification-dropdown {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: var(--color-white);
  color: var(--color-dark);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  z-index: 1000;
  padding: 1rem;
  animation: fadeIn 0.3s ease;
}

.notification-dropdown h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.notification-item {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--color-light-gray);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item:hover {
  background-color: var(--color-light-gray);
}

.notification-item.unread h4 {
  font-weight: bold;
}

.notification-item.read h4 {
  color: var(--color-dark);
}

.no-notifications {
  text-align: center;
  color: var(--color-dark);
  font-size: 0.9rem;
}

.view-all-link {
  display: block;
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
  color: var(--color-primary);
  text-decoration: none;
}

.view-all-link:hover {
  text-decoration: underline;
}

/* Badge de Notificações */
.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: var(--color-white);
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Search */
.header-search {
  flex: 1;
  display: flex;
  justify-content: center;
}

.search-bar {
  width: 100%;
  max-width: 500px;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  font-size: 1rem;
}
