.business-register-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--color-light-gray);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.business-register-container h2 {
  text-align: center;
  color: var(--color-primary);
  margin-bottom: 1.5rem;
}

.business-register-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 1rem;
  color: var(--color-dark);
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

input,
textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  font-size: 1rem;
  color: var(--color-dark);
}

textarea {
  resize: none;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.submit-button {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 0.8rem;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--color-secondary);
}
