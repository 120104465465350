.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  color: var(--color-primary);
  margin-bottom: 20px;
}

.preferences-section {
  margin-bottom: 20px;
}

.preferences-section h3 {
  color: var(--color-dark);
  margin-bottom: 10px;
}

.preferences-section label {
  display: block;
  margin-bottom: 10px;
  color: var(--color-dark);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.save-button {
  background-color: var(--color-secondary);
  color: var(--color-white);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f44336;
  color: var(--color-white);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover,
.cancel-button:hover {
  opacity: 0.9;
}
