/* Contêiner Principal */
.agendamentos-container-dad {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.agendamentos-container {
  width: 50%;
  display: flex;
  gap: 20px;
  padding: 0 20px;
  flex-direction: column;
}
/* Tabs */
.tabs-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tab-button {
  width: 100%;
  background-color: var(--color-light-gray);
  color: var(--color-dark);
  border: 2px solid var(--color-primary);
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  /* border-radius: 8px; */
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.tab-button:hover {
  background-color: var(--color-accent);
  color: var(--color-white);
}

/* Lista de Agendamentos */
.lista-agendamentos {
  flex: 1;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
  max-height: 80vh;
  padding: 10px;
}

.agendamento-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--color-light-gray);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.agendamento-item:hover {
  background-color: var(--color-light-gray);
}

.item-content {
  display: grid;
  grid-template-columns: 50px 1fr 150px;
  align-items: center;
  width: 100%;
}

.item-icon {
  text-align: center;
  font-size: 20px;
}

.item-details {
  text-align: left;
}

.item-details h4 {
  margin: 0;
  font-size: 16px;
  color: var(--color-dark);
}

.item-details p {
  margin: 0;
  font-size: 14px;
  color: var(--color-dark);
}

/* Detalhes do Agendamento */
.detalhes-agendamento {
  flex: 2;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.detalhes-agendamento h2 {
  color: var(--color-primary);
  margin-bottom: 10px;
}

.detalhes-agendamento p {
  margin: 5px 0;
  font-size: 16px;
  line-height: 1.5;
}

.botao-editar {
  background-color: var(--color-accent);
  color: var(--color-white);
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.botao-cancelar {
  background-color: #f44336;
  color: var(--color-white);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.botao-editar:disabled,
.botao-cancelar:disabled {
  background-color: var(--color-light-gray);
  color: var(--color-dark);
  cursor: not-allowed;
}

.botao-editar:hover,
.botao-cancelar:hover {
  opacity: 0.9;
}
