/* Contêiner principal */

/* Título */
.payment-settings-container h2 {
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 20px;
}

/* Lista de métodos de pagamento */
.payment-methods-list {
  margin-bottom: 20px;
}

.payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.payment-method:hover {
  background-color: var(--color-light-gray);
}

.default-method {
  border: 2px solid var(--color-primary);
  background-color: rgba(108, 99, 255, 0.1); /* Destaque para o método padrão */
}

/* Botões de ação */
.method-actions button {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.method-actions button:hover {
  background-color: var(--color-secondary);
}

/* Seção de adicionar método */
.add-payment-method {
  margin-top: 20px;
}

.add-payment-method h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: var(--color-dark);
}

.add-payment-method select,
.add-payment-method input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
}

.add-payment-method button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-payment-method button:hover {
  background-color: var(--color-secondary);
}
