/* Container Principal */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
  background: var(--color-light-gray);
}

/* Header */
.home-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  background: var(--color-primary);
  color: var(--color-white);
}

.header-left .home-logo {
  width: 150px;
}

.header-center .search-bar {
  width: 100%;
  max-width: 500px;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  font-size: 1rem;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Seção de Boas-Vindas */
.welcome-section {
  text-align: center;
  margin: 2rem 0;
}

.welcome-section h1 {
  font-size: 2.5rem;
  color: var(--color-dark);
}

.welcome-section p {
  font-size: 1.2rem;
  color: var(--color-accent);
}

/* Promoções */
.promotions-section {
  width: 100%;
  max-width: 800px;
  margin: 2rem 0;
  text-align: center;
}

.promotions-section h2 {
  font-size: 2rem;
  color: var(--color-primary);
  margin-bottom: 1rem;
}

.promotions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.promotion-card {
  background: var(--color-white);
  border: 1px solid var(--color-light-gray);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  text-align: left;
}

.promotion-card h3 {
  font-size: 1.5rem;
  color: var(--color-secondary);
}

.promotion-card p {
  font-size: 1rem;
  color: var(--color-dark);
}

/* Recomendações */
.recommendations-section {
  width: 100%;
  max-width: 800px;
  margin: 2rem 0;
  text-align: center;
}

.recommendations {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.recommendation-card {
  background: var(--color-white);
  border: 1px solid var(--color-light-gray);
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  text-align: left;
}

.recommendation-card h3 {
  font-size: 1.5rem;
  color: var(--color-secondary);
}

.recommendation-card p {
  font-size: 1rem;
  color: var(--color-dark);
}
