.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.logo {
  width: 80px;
  height: 80px;
}

.logo-text {
  font-size: 2rem;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.logo-link {
  text-decoration: none;
}

.logo-link:hover {
  color: #f9f9f9; /* Cor de destaque ao passar o mouse */
}
