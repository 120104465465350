/* Geral */
.meu-plano-container {
  display: flex;
  gap: 20px;
  padding: 20px;
}

/* Menu Lateral */
.plano-sidebar {
  width: 25%;
  background-color: var(--color-light-gray);
  padding: 15px;
  border-radius: 8px;
}

.plano-sidebar h3 {
  margin-bottom: 10px;
  color: var(--color-primary);
}

.subscription-list {
  list-style: none;
  padding: 0;
}

.subscription-list li {
  margin-bottom: 10px;
  font-size: 14px;
}

.progress-bar {
  margin-top: 20px;
}

.progress {
  background-color: var(--color-white);
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: var(--color-primary);
}

/* Conteúdo Principal */
.main-content {
  width: 70%;
}

/* Botões */
.actions {
  display: flex;
  gap: 10px;
}

.details-button {
  background-color: var(--color-accent);
}

.cancel-button {
  background-color: var(--color-secondary);
  color: var(--color-white);
}

.change-button {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.subscription-item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid var(--color-light-gray);
  transition: background-color 0.3s ease;
}

.subscription-item:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 5px;
}

.plan-card .actions button {
  margin: 0 5px; /* Espaçamento entre os botões */
}

.plan-card {
  border: 1px solid var(--color-light-gray);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}
