/* Contêiner Principal */
.security-settings-container {
  margin-bottom: 20px;
  /* max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* Título Principal */
.security-settings-container h2 {
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 20px;
}

/* Seções */
.security-section {
  margin-bottom: 30px;
}

.security-section h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: var(--color-primary);
}

/* Switch de 2FA */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin-bottom: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-dark);
  border-radius: 25px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: var(--color-white);
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--color-secondary);
}

input:checked + .slider:before {
  transform: translateX(25px);
}

/* Lista de Dispositivos */
.device-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.device-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid var(--color-light-gray);
}

.remove-device {
  background-color: var(--color-secondary);
  color: var(--color-white);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-device:hover {
  background-color: #ff4d4d;
}

/* Input */
.security-section input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  background-color: var(--color-white);
  margin-top: 10px;
  transition: border-color 0.3s ease;
}

.security-section input:focus {
  border-color: var(--color-primary);
  outline: none;
}

/* Lista de Atividades */
.activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activity-list li {
  padding: 10px;
  border-bottom: 1px solid var(--color-light-gray);
  color: var(--color-dark);
  font-size: 0.9rem;
}

/* Botão Salvar */
.save-button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: var(--color-secondary);
}
