.react-tel-input .country-list {
  display: flex;
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: white;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
  flex-direction: column;
  align-items: flex-start;
}
.phone-field {
  padding: 10px;
  margin: 10px 0;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
