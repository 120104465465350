/* mvp\front_web\linka\src\components\Sidebar\Sidebar.css */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 220px;
  height: 100vh;
  background: linear-gradient(
    135deg,
    var(--color-primary),
    var(--color-accent)
  );
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 1500; /* Maior que o overlay (1000) */
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.sidebar.open {
  transform: translateX(0);
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--color-white);
  cursor: pointer;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 20px;
}

.sidebar-link {
  color: var(--color-white);
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px 0;
  transition: background 0.3s ease;
}

.sidebar-link:hover {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

/* Overlay (definido no Header.jsx, mas estilos aqui) */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.overlay.show {
  opacity: 1;
  pointer-events: all;
}
