.user-settings-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  background-color: var(--color-light-gray);
}

.settings-sidebar {
  width: 25%;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.sidebar-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: var(--color-light-gray);
  color: var(--color-dark);
  border: none;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.sidebar-button.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.sidebar-button:hover {
  background-color: var(--color-accent);
  color: var(--color-white);
}

/* .settings-content {
  flex: 1;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
} */

.settings-content {
  display: flex;
  flex: 1 1;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  align-content: space-between;
  flex-direction: column;
}

.notification-preferences-container {
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}
.notification-preferences-container h2 {
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 20px;
}

.privacy-settings-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: nowrap;
}

.language-settings-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: nowrap;
}

.geolocation-session {
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}

.geolocation-session h2 {
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 20px;
}
