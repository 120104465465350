/* Container Principal */
/* .account-settings-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.account-settings-container h2 {
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 20px;
}

/* Formulário */
.account-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--color-dark);
}

.form-group input {
  padding: 10px;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: var(--color-primary);
  outline: none;
}

/* Botão de Salvar */
.save-button {
  padding: 12px 20px;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: var(--color-accent);
}

/* Seção de Exclusão de Conta */
.delete-account-section {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
}

.delete-account-section h3 {
  color: var(--color-secondary);
  margin-bottom: 10px;
}

.delete-account-section p {
  margin-bottom: 20px;
  color: var(--color-dark);
}

/* Botão de Exclusão */
.delete-button {
  padding: 12px 20px;
  background-color: #f44336;
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.delete-button:hover {
  background-color: #d32f2f;
}

.delete-button:disabled {
  background-color: #e57373;
  cursor: not-allowed;
}

/* Botão de Exclusão com Loading */
.delete-button.loading {
  opacity: 0.8;
  pointer-events: none;
}
