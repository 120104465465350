/* index.css ou global.css */
:root {
  --color-primary: #6c63ff; /* Roxo Primário */
  --color-secondary: #28a745; /* Verde de Destaque */
  --color-accent: #4f8cf5; /* Azul Claro */
  --color-white: #ffffff; /* Branco */
  --color-light-gray: #f9f9f9; /* Cinza Claro */
  --color-dark: #333333; /* Preto Suave */
}

/* Ajuste o background global ou outros estilos globais */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: var(--color-light-gray);
  color: var(--color-dark);
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

button {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
