.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(180deg, #f9f9f9, #ffffff);
}

.main-content {
  margin: auto;
  flex: 1; /* Ensures the main content expands to fill available space */
  padding: 2rem;
}
