.notifications-container {
  padding: 20px;
  background-color: var(--color-light-gray);
}

.notifications-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-filters {
  margin: 10px 0;
  display: flex;
  gap: 10px;
}

.filter-button {
  color: #0056b3;
  padding: 10px 20px;
  border: solid 1px var(--color-dark);
  border-radius: 5px;
  background-color: var(--color-light-gray);
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-button.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.notifications-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notification-card {
  padding: 15px;
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-card.read {
  opacity: 0.7;
}

.notification-actions button {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}

.mark-as-read {
  background-color: var(--color-secondary);
  color: var(--color-white);
}

.delete-notification {
  background-color: #f44336;
  color: var(--color-white);
}
