/* .language-settings-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.language-settings-container h2 {
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 20px;
}

/* Formulário */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--color-dark);
}

.form-group select {
  padding: 10px;
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  font-size: 1rem;
  background-color: var(--color-white);
  transition: border-color 0.3s;
}

.form-group select:focus {
  border-color: var(--color-primary);
  outline: none;
}

/* Botão de Salvar */
.save-button {
  padding: 12px 20px;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: var(--color-accent);
}
