.business-menu {
  position: relative;
  display: inline-block;
}

.menu-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
}

.menu-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: var(--color-white);
  border: 1px solid var(--color-light-gray);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 280px;
  z-index: 1000;
  overflow: hidden;
}

.menu-header {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  background-color: var(--color-light-gray);
}

.header-icon {
  margin-right: 8px;
  color: var(--color-primary);
}

.create-business {
  background: none;
  border: none;
  color: var(--color-primary);
  font-weight: bold;
  cursor: pointer;
}

.create-business:hover {
  text-decoration: underline;
}

.business-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.business-item {
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.business-item:hover {
  color: var(--color-secondary);
  background-color: var(--color-light-gray);
}

.business-info h4 {
  margin: 0;
  font-size: 16px;
}

.business-info p {
  margin: 0;
  font-size: 12px;
  color: var(--color-dark-gray);
}

.selected {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.selected-icon {
  color: var(--color-secondary);
}

/* Torna a seção de "Criar Novo Negócio" clicável */
.menu-header.clickable {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: background-color 0.3s ease;
}

.menu-header.clickable:hover {
  background-color: #f1f1f1; /* Efeito visual no hover */
}

.create-business-text {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-dark);
}
