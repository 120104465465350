/* Geral */
.user-profile {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  text-align: center;
}

.user-profile h1 {
  font-size: 26px;
  margin-bottom: 20px;
  color: #333;
}

/* Avatar */
.profile-info img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 3px solid #007bff;
}

/* Informações do usuário */
.profile-info p {
  font-size: 16px;
  color: #555;
  margin: 5px 0;
}

/* Botões */
.profile-info button {
  margin: 10px 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.profile-info button:hover {
  background-color: #0056b3;
  color: white;
}

.profile-info button:active {
  transform: scale(0.98);
}

.profile-info button:first-of-type {
  background-color: #007bff;
  color: white;
}

.profile-info button.delete-btn {
  background-color: #dc3545;
  color: white;
}

.profile-info button.delete-btn:hover {
  background-color: #c82333;
}

/* Formulário de edição */
.edit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.edit-form input {
  margin: 10px 0;
  padding: 10px;
  width: 80%;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.edit-form input:focus {
  outline: none;
  border-color: #007bff;
}

.edit-form button {
  margin: 10px 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.edit-form button:first-of-type {
  background-color: #28a745;
  color: white;
}

.edit-form button:last-of-type {
  background-color: #6c757d;
  color: white;
}

/* Responsividade */
@media (max-width: 600px) {
  .user-profile {
    margin: 20px;
    padding: 15px;
  }

  .profile-info img {
    width: 100px;
    height: 100px;
  }

  .edit-form input {
    width: 100%;
  }
}

.user-profile {
  max-width: 800px;
  margin: 80px auto;
  padding: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.user-profile h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
  text-transform: capitalize;
}

.user-profile .profile-info img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 4px solid #007bff;
}

.user-profile .profile-info p {
  font-size: 18px;
  color: #555;
  margin: 10px 0;
}
.nav-btn {
  margin: 10px 5px;
  padding: 12px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-btn:hover {
  background-color: #45a049;
}

.btn-group {
  display: flex;
}

@media (max-width: 768px) {
  .user-profile {
    margin: 20px;
    padding: 20px;
  }

  .user-profile h1 {
    font-size: 22px;
  }

  .profile-info img {
    width: 100px;
    height: 100px;
  }

  .nav-btn {
    padding: 10px 15px;
    font-size: 14px;
  }
}
